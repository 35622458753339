export const INQUIRY_ICON_FALLBACK =
    "https://storage.googleapis.com/v26kbrqn/inquiry/icons/inquiry_default_icon.png";

export const OPPLY_SQUARE_LOGO_ICON = "/images/logo-square.svg";

/**
 * List of supported allergens on platform for inquiry creation/edit
 */
export const supportedAllergens = [
    "celery",
    "cereals_containing_gluten",
    "crustaceans",
    "eggs",
    "fish",
    "lupin",
    "milk",
    "molluscs",
    "mustard",
    "nuts",
    "peanuts",
    "sesame_seeds",
    "soya",
    "sulphur_dioxide",
];

/**
 * List of supported dietary requirements on platform for inquiry creation/edit
 */
export const supportedDietaryRequirements = [
    "kosher",
    "halal",
    "organic",
    "vegan",
    "vegetarian",
];

/**
 * List of price disclaimer points shown on inquiry creation/edit
 */
export const pricingDisclaimerList = [
    "All prices are given in local currency as per your selected country",
    "Target unit price will not be shown to suppliers",
];

/**
 * List of available frequency options on platform for inquiry creation/edit
 */
export const forecastOptions = [
    { label: "Weekly", value: "weekly" },
    { label: "Monthly", value: "monthly" },
    { label: "Quarterly", value: "quarterly" },
    { label: "Annually", value: "annually" },
];

export const frequencyOptions = forecastOptions.map((option) => option.value);

export const HOTJAR_USER_ID = "2939969";

/**
 * height and with for chatbox
 */
export const ChatBoxDimensions = {
    DEFAULT_WIDTH: "496px",
    DEFAULT_HEIGHT: "560px",
    EXPANDED_WIDTH: "calc(100vw - (17% + 80px))",
    EXPANDED_HEIGHT: "calc(100vh - 74px)",
    EXPANDED_WIDTH_WITH_HIDDEN_MENU: "calc(100vw - 80px)",
};

/**
 * list of supported currencies on platform
 */
export const currencyList = [
    { label: "GBP", value: "GBP" },
    { label: "EUR", value: "EUR" },
    { label: "USD", value: "USD" },
    { label: "CAD", value: "CAD" },
];

export const monthFieldOptions = [
    { label: "1 month", value: 1 },
    { label: "2 months", value: 2 },
    { label: "3 months", value: 3 },
    { label: "4 months", value: 4 },
    { label: "5 months", value: 5 },
    { label: "6 months", value: 6 },
    { label: "7 months", value: 7 },
    { label: "8 months", value: 8 },
    { label: "9 months", value: 9 },
    { label: "10 months", value: 10 },
    { label: "11 months", value: 11 },
    { label: "12 months", value: 12 },
];

export const weekFieldOptions = [
    { label: "Every Week", value: 1 },
    { label: "Every 2 Weeks", value: 2 },
    { label: "Every 3 Weeks", value: 3 },
    { label: "Every 4 Weeks", value: 4 },
    { label: "Every 5 Weeks", value: 5 },
    { label: "Every 6 Weeks", value: 6 },
    { label: "Custom", value: 0 },
];

export const timePeriodOptions = [
    { label: "Days", value: "day" },
    { label: "Months", value: "month" },
    { label: "Years", value: "year" },
];

export const incoterms = [
    {
        label: "Carriage and Insurance Paid to (CIP)",
        value: "carriage_and_insurance_paid_to",
    },
    {
        label: "Carriage Paid To (CPT)",
        value: "carriage_paid_to",
    },
    {
        label: "Cost and Freight (CFR)",
        value: "cost_and_freight",
    },
    {
        label: "Cost, Insurance and Freight (CIF)",
        value: "cost_insurance_and_freight",
    },

    {
        label: "Delivered At Place (DAP)",
        value: "delivered_at_place",
    },
    { label: "Delivery Duty Paid (DDP)", value: "delivery_duty_paid" },
    { label: "Delivery Duty Unpaid (DDU)", value: "delivery_duty_unpaid" },
    {
        label: "Ex Works (EXW)",
        value: "ex_works",
    },
    {
        label: "Free Alongside Ship (FAS)",
        value: "free_alongside_ship",
    },
    {
        label: "Free on Board (FOB)",
        value: "free_on_board",
    },
];

export const feedbackItems = [
    {
        label: "Ingredient Requests",
        href: "https://opply.canny.io/ingredient-requests",
        testId: "feedback-menu__ingredient-request",
    },
    {
        label: "Feature Requests",
        href: "https://opply.canny.io/feature-requests",
        testId: "feedback-menu__feature-request",
    },
];

export const forcedLoginTimestamp = "2025-02-05T00:00:00Z";
