import { handleError } from "@/utils";
import { computed, ref } from "vue";
import { defineStore } from "pinia";
import Notifications from "@/services/Notifications";
import WebSocketsService from "@/services/WebSockets";
import { useChatListStore } from "@/stores/chatList";
import { useChatBoxStore } from "@/stores/chatbox";
import type { Writeable } from "@/types";
import type {
    Notification,
    PaginatedNotificationList,
} from "@/types/api/data-contracts";
import type { IMessageData, ISocketEvent } from "@/types/Notification";

export const useNotificationsStore = defineStore("notifications", () => {
    const chatBoxStore = useChatBoxStore();
    const chatListStore = useChatListStore();
    const notificationsService = Notifications.getInstance();
    const webSocketService = WebSocketsService.getInstance();

    const emptyNotificationsValue = {
        count: 0,
        results: [],
        next: null,
        previous: null,
        unread_count: 0,
    };

    //#region state
    const notifications = ref<Writeable<PaginatedNotificationList>>(
        emptyNotificationsValue
    );

    const connected = ref<boolean>(false);
    const socket = ref<WebSocket | null>(null);
    const loading = ref(false);
    const currentPage = ref(1);

    let reconnectIntervalId: number | null = null;

    const tryReconnect = () => {
        if (reconnectIntervalId) return; // If reconnection is already in progress, skip

        reconnectIntervalId = window.setInterval(() => {
            console.log("Attempting to reconnect WebSocket...");
            connect(); // Attempt to reconnect every 10 seconds
        }, 10000);
    };

    /**
     * Sets the loading value to false.
     * Should be used after start fetching notifications.
     */
    const finishLoading = () => {
        loading.value = false;
    };

    const resetNotifications = () => {
        notifications.value = emptyNotificationsValue;
        notifications.value.results = [];
    };

    /**
     * Increments the value of the current page by 1.
     * This will change the behaviour of `fetchNotifications()` which will
     * request notifications of the given current page.
     */
    const incrementCurrentPage = () => {
        currentPage.value += 1;
    };

    const countUnreadNotifications = computed(
        () => notifications.value.unread_count || 0
    );

    /**
     * Determines if is possible to load more notifications.
     * If server response contains value for the `next` property
     * means that it is possible to load more.
     * Note: the server responds with the full URL to the next page,
     * but we only need to know if there is a next page.
     */
    const canLoadMore = computed(() => !!notifications.value.next);

    const justReadNotifications = ref<string[]>([]);
    //#endregion state

    //#region actions
    /**
     * Initiates a WebSocket connection.
     */
    const connect = () => {
        if (connected.value) return; // Avoid duplicate connections

        const socketInstance = webSocketService.establishConnection();

        if (!socketInstance?.value) {
            handleError(
                "Failed to initialise notifications, please refresh the page",
                true
            );
            tryReconnect();
            return null;
        }

        socket.value = socketInstance.value;

        if (!socket.value) return null;

        connected.value = true;

        socket.value.addEventListener("message", (event) => {
            if (!notifications.value.unread_count) {
                notifications.value.unread_count = 0;
            }

            const message = JSON.parse(event.data) as ISocketEvent;

            if (message.model === "Notification") {
                const notificationData = message.data;

                if (notifications.value.results) {
                    notifications.value.results.unshift(notificationData);
                }

                notifications.value.unread_count += 1;
            } else if (message.model === "Message") {
                const chatListData = message.data as IMessageData;

                chatListStore.appendDataToChatList(chatListData);

                chatListStore.increaseUnreadCount(message.data);

                chatBoxStore.processNewMessage(message.data);
            }
        });

        socket.value.addEventListener("close", () => {
            connected.value = false;
            socket.value = null;
            tryReconnect();
        });

        socket.value.addEventListener("error", (error) => {
            console.error("WebSocket error:", error);
            connected.value = false;
            socket.value = null;
            tryReconnect();
        });

        if (reconnectIntervalId) {
            clearInterval(reconnectIntervalId);
            reconnectIntervalId = null;
        }

        return socket;
    };

    /**
     * Terminates a WebSocket connection.
     */
    const disconnect = () => {
        if (!socket.value || !connected.value) return;

        console.log("Closing WebSocket connection...");

        try {
            socket.value.close();
            connected.value = false;
            console.log("WebSocket connection closed");
        } catch (error) {
            console.error("Error closing WebSocket connection", error);
        }

        if (reconnectIntervalId) {
            clearInterval(reconnectIntervalId);
            reconnectIntervalId = null;
        }
    };

    /**
     * Makes a request to mark a notification as "read".
     * Also updates store state with the new `is_read` value.
     * @param notificationUuid
     */
    const markNotificationAsRead = async (notificationUuid: string) => {
        justReadNotifications.value.push(notificationUuid);

        const readNotification =
            notifications.value.results &&
            notifications.value.results.find(
                (notification) => notification.uuid === notificationUuid
            );

        if (readNotification) {
            readNotification.is_read = "true";
        }

        if (!notifications.value.unread_count) {
            // Unlikely to happen, it's more of an handling for typescript
            notifications.value.unread_count = 0;
        } else {
            notifications.value.unread_count -=
                notifications.value.unread_count;
        }
    };

    /**
     * Loops through the just read notifications and
     * marks as read all related notifications on the
     * newNotifications object.
     * @param newNotifications
     */
    const processJustReadNotifications = (
        newNotifications: Writeable<Notification>[]
    ) => {
        justReadNotifications.value.map(async (justReadNotification) => {
            const readNotification = newNotifications.find(
                (notification) => notification.uuid === justReadNotification
            );

            if (readNotification) {
                readNotification.is_read = "true";
            }
        });

        justReadNotifications.value = [];
    };

    /**
     * Makes a request to fetch notifications and updates the notification ref object.
     */
    const fetchNotifications = async (
        loadOnlyIfEmpty = false,
        clearAndReload = false
    ) => {
        if (loadOnlyIfEmpty && notifications.value.results?.length) return; // Do not load if we have already notifications

        if (clearAndReload) resetNotifications();

        const allNotifications = await notificationsService.getNotifications(
            currentPage.value
        );

        processJustReadNotifications(
            allNotifications.data.results as Notification[]
        );

        if (!notifications.value.results) notifications.value.results = [];

        notifications.value.next = allNotifications.data.next;

        notifications.value.unread_count = allNotifications.data.unread_count;

        notifications.value.results = [
            ...notifications.value.results,
            ...(allNotifications.data.results || []),
        ];

        finishLoading();
    };

    /**
     * Increments the current page and loads more notifications.
     */
    const fetchMoreNotifications = async () => {
        incrementCurrentPage();
        await fetchNotifications();
    };

    /**
     * Given an object ID (e.g. quote or inquiry id) will mark all notifications
     * related to that object as read.
     * @param relatedObjectUuid
     * @param notificationType
     */
    const markRelatedNotificationsAsRead = async (
        relatedObjectUuid: string,
        notificationType: "inquiry" | "quote" | "contract" | "order"
    ) => {
        try {
            await notificationsService.markRelatedNotificationsAsRead(
                relatedObjectUuid,
                notificationType
            );
        } catch (error) {
            handleError(error);
        }

        const isUnread =
            notifications.value.results &&
            notifications.value.results.some(
                (notification) =>
                    notification.related_object_type ===
                        `${notificationType}` &&
                    notification.related_object_uuid === relatedObjectUuid &&
                    !notification.is_read
            );

        if (!notifications.value.results?.length || !isUnread) return;

        const promises = notifications.value.results?.map(
            async (notification) => {
                if (
                    notification.category !== `${notificationType}s` ||
                    notification.related_object_uuid !== relatedObjectUuid ||
                    notification.is_read
                )
                    return false;

                return markNotificationAsRead(notification.uuid);
            }
        );

        if (promises) {
            await Promise.all(promises).then(() => {
                currentPage.value = 1;
                fetchNotifications(false, true);
            });
        }
    };
    //#endregion actions

    /**
     * Fetches all notifications and marks the notifications of a specified type
     * as read.
     * @param {string} objectUuid - The uuid of the object that needs to be
     * marked as read (e.g. could be a contract uuid)
     * @param notificationType - The type of the object that needs to be marked as read.
     */
    const loadNotifications = async (
        objectUuid: string,
        notificationType: "inquiry" | "quote" | "contract" | "order"
    ) => {
        await fetchNotifications();

        await markRelatedNotificationsAsRead(objectUuid, notificationType);
    };

    const $reset = () => {
        resetNotifications();
        loading.value = false;
        currentPage.value = 1;
    };

    return {
        $reset,
        countUnreadNotifications,
        notifications,
        canLoadMore,
        connected,
        connect,
        disconnect,
        fetchNotifications,
        markNotificationAsRead,
        markRelatedNotificationsAsRead,
        loadNotifications,
        fetchMoreNotifications,
        resetNotifications,
        loading,
        currentPage,
    };
});
